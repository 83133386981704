import * as eodConstants from './eodManagement.actions.constants';

const initialState = {
  eodScheduleData: {},
  eodHistoryData: {}
};

const eodManagementReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case eodConstants.SET_EOD_SCHEDULE:
      return { ...state, eodScheduleData: data };

    case eodConstants.SET_EOD_HISTORY:
      return { ...state, eodHistoryData: data };
    default:
      return state;
  }
};

export default eodManagementReducer;
