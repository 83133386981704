import React, { Component } from 'react';
import HeaderTop from '../../components/HeaderTop';
import { Helmet } from 'react-helmet';
import Arrow from '../../assets/images/LeftArrow.svg';
import Clipboard from '../../assets/images/ClipBoard.svg';
import Setting from '../../assets/images/SettingsActive.svg';
import CredoModal from '../../components/CredoModal';
import ManualEodModal from './ManualEodModal';
import EodCard from './EodCard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEodSchedule, getEodHistory } from './eodManagement.actions';
import { loaderModal } from '../../store/actions';

class EodManagement extends Component {
  state = {
    modalOpen: false
  };

  componentDidMount = async () => {
    const { actions } = this.props;
    const { getEodSchedule, getEodHistory, loaderModal } = actions;
    loaderModal(true);
    await getEodSchedule();
    await getEodHistory();
    loaderModal(false);
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }));
  };

  render() {
    const { eodScheduleData, eodHistoryData } = this.props;
    let { modalOpen } = this.state;
    return (
      <div className='eod-wrapper'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>EOD</title>
        </Helmet>
        <div className='eod_header'>
          <HeaderTop {...this.props}>
            <img src={Setting} alt=' logo' className='logo_image ml-5 ' />
          </HeaderTop>
        </div>
        <div className='container'>
          <div className='main_title'>
            <div className='row'>
              <div className='col-md-10 main_title-primary'>
                <img
                  src={Arrow}
                  alt='image'
                  onClick={this.props.history.goBack}
                />
                Scheduled EOD
              </div>
              <div className='col-md-2 text-right' onClick={this.toggleModal}>
                {eodScheduleData.systemStatus === 'Active' ? (
                  <button>
                    <img src={Clipboard} alt='image' />
                    Manual EOD
                  </button>
                ) : (
                  <button>
                    <img src={Clipboard} alt='image' />
                    System Start
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className='eod_card '>
            <div className='eod_card_table'>
              <div className='row eod_card_table-heading'>
                <div className='col-md-2'>EoD Schedule</div>
                <div className='col-md-2'>Frequency</div>
                <div className='col-md-2'>Mode</div>
                <div className='col-md-2'>Current Status</div>
                <div className='col-md-2'>System Status</div>
              </div>
            </div>

            <div className='row eod_card_table-content'>
              <div className='col-md-2'>{eodScheduleData.nextRunAt ?? '-'}</div>
              <div className='col-md-2'>{eodScheduleData.frequency ?? '-'}</div>
              <div className='col-md-2'>{eodScheduleData.mode ?? '-'}</div>
              <div className='col-md-2'>
                {eodScheduleData.currentStatus ?? '-'}
              </div>
              <div className='col-md-2'>
                {eodScheduleData.systemStatus ?? '-'}
              </div>
            </div>
          </div>
          <div className='row main_title'>
            <div className='col-md-12 main_title-primary'>EOD History</div>
          </div>

          <div className='eod-wrapper'>
            {Object.values(eodHistoryData).map((data, index) => {
              return <EodCard data={data} key={`${index}eod`} />;
            })}
          </div>
        </div>
        <CredoModal
          isOpen={modalOpen}
          styles={{
            content: {
              minWidth: '40vw',
              height: '30vh'
            }
          }}
          closeAction={() => this.toggleModal('')}
          RenderingComponent={ManualEodModal}
          stateData={this.state}
          propsData={this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ eodManagementReducer }) => ({
  eodScheduleData: eodManagementReducer.eodScheduleData,
  eodHistoryData: eodManagementReducer.eodHistoryData
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getEodSchedule,
      getEodHistory,
      loaderModal
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(EodManagement);
