import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/es";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/app.css";
import App from "./App";
import { Provider } from "react-redux";
import createStore from "./store/store";

const store = createStore({});
const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
};

render(App);
