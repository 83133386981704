import React, { Component } from "react";

export default class BulkInvoiceModal extends Component {
  render() {
    return (
      <div className="compare-details-wrapper">
        <h3 className="card_heading">Bulk Invoice Upload</h3>
      </div>
    );
  }
}
