const SET_ACTIVE_LOAN = Symbol('setActiveLoan'),
  SET_OUTSTANDING_INVOICES = Symbol('setOutstandingInvoices'),
  SET_SETTLED_INVOICES = Symbol('setSettledInvoices'),
  SET_LIMIT_SUMMARY = Symbol('setLimitSummary'),
  SET_REPAYMENTS = Symbol('setRepayments');
export {
  SET_ACTIVE_LOAN,
  SET_LIMIT_SUMMARY,
  SET_OUTSTANDING_INVOICES,
  SET_SETTLED_INVOICES,
  SET_REPAYMENTS
};
