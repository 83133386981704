import { rupeeDecimalFormatter } from '../../utils/utility';

export const chargeHistoryColumns = [
  {
    Header: 'Created Date',
    accessor: 'createdDate'
  },

  {
    Header: 'Effective Date',
    accessor: 'effectiveDate'
  },
  {
    Header: 'Charge Name',
    accessor: 'chargeName'
  },
  {
    Header: 'Charge Amt.',
    accessor: 'chargeAmount',
    headerStyle: { textAlign: 'right' },
    Cell: props => {
      return (
        <div className="right-aligned ">
          {rupeeDecimalFormatter(props?.value, '₹')}
        </div>
      );
    }
  },
  {
    Header: 'GST',
    accessor: 'gstAmount',
    headerStyle: { textAlign: 'right' },
    Cell: props => {
      return (
        <div className="right-aligned ">
          {rupeeDecimalFormatter(props?.value, '₹')}
        </div>
      );
    }
  },
  {
    Header: 'Total Charge Amt.',
    accessor: 'totalCharge',
    headerStyle: { textAlign: 'right' },
    Cell: props => {
      return (
        <div className="right-aligned ">
          {rupeeDecimalFormatter(props?.value, '₹')}
        </div>
      );
    }
  },
  {
    Header: 'Posting',
    Cell: ({ original }) => {
      let { isManual } = original;
      return (
        <>
          {isManual ? (
            <span className="capsule capsule-blue">Manual</span>
          ) : (
            <span className="capsule capsule-green">Auto</span>
          )}
        </>
      );
    }
  }
];
