import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEodSchedule, getEodHistory } from './eodManagement.actions';
import { POST } from '../../utils/webAPI.service';
import { API_POST_EOD_START, API_POST__SOD_START } from '../../utils/APIUrls';

class ManualEodModal extends Component {
  eodStartHandler() {
    POST(API_POST_EOD_START)
      .then(({ data }) => {
        if (data.code == 200) {
          const { actions } = this.props;
          const { getEodSchedule, getEodHistory } = actions;
          getEodSchedule();
          getEodHistory();
        } else {
          alert(data?.message);
        }
      })
      .catch(err => {
        throw err;
      });
  }

  sodStartHandler() {
    POST(API_POST__SOD_START)
      .then(data => {
        if (data.code == 200) {
          const { actions } = this.props;
          const { getEodSchedule, getEodHistory } = actions;
          getEodSchedule();
          getEodHistory();
        } else {
          alert(data?.message);
        }
      })
      .catch(err => {
        throw err;
      });
  }
  render() {
    let { propsData } = this.props,
      { eodScheduleData } = propsData,
      { systemStatus } = eodScheduleData;

    return (
      <div className="modal_card eod-wrapper">
        <h3 className="modal_card-heading">Manual EOD</h3>
        <div className="offer_wrapper">
          <div className="unlock_box">
            {systemStatus === 'Active'
              ? ' Are you sure you want to initiate EOD'
              : 'Are you sure you want to initiate SOD'}
          </div>
          <div className="limit_button ">
            <button
              className="modal_button inactive_button"
              onClick={this.props.closeAction}
            >
              No, Cancel
            </button>
            <button
              className="modal_button active_button"
              onClick={e => {
                e.preventDefault();
                systemStatus === 'Active'
                  ? this.eodStartHandler()
                  : this.sodStartHandler();

                this.props.closeAction();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ eodManagementReducer }) => ({
  eodScheduleData: eodManagementReducer.eodScheduleData,
  eodHistoryData: eodManagementReducer.eodHistoryData
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getEodSchedule,
      getEodHistory
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualEodModal);
