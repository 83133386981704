import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

class DatePickerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldActive: !!props.selected
    };
  }

  componentWillReceiveProps(nextProps) {
    let { fieldActive } = this.state;
    nextProps.selected
      ? !fieldActive && this.setState({ fieldActive: true })
      : fieldActive && this.setState({ fieldActive: false });
  }

  render() {
    const {
      datePickerClass,
      labelText,
      name,
      selected,
      disabled,
      boxClass,
      tabIndex,
      placeholderText,
      maxDate,
      minDate,
      defaultValue,
      onChange
    } = this.props;
    return (
      <div
        className={
          disabled
            ? `datepicker-box datepicker-disabled ${boxClass}`
            : `datepicker-box ${boxClass}`
        }
      >
        <label>{labelText}</label>
        <DatePicker
          dateFormat="dd-MM-yyyy"
          autoComplete="off"
          disabled={disabled}
          ref={ref => (this.dateField = ref)}
          tabIndex={tabIndex}
          showMonthDropdown
          showYearDropdown
          dropdownMode={'scroll'}
          selected={selected}
          onChangeRaw={e => e.preventDefault()}
          onFocus={this.activateField}
          onBlur={this.disableField}
          onChange={onChange}
          maxDate={maxDate}
          minDate={minDate}
          defaultValue={defaultValue}
          name={name}
          required={true}
          placeholderText={placeholderText}
          className={`date-field floating-label ${datePickerClass}`}
        />
      </div>
    );
  }
}

DatePickerComponent.defaultProps = {
  datePickerClass: '',
  labelText: '',
  required: false,
  name: '',
  selected: '',
  disabled: false,
  boxClass: '',
  tabIndex: -1,
  placeholderText: '',
  onChange: () => {},
  maxDate: new Date(),
  defaultValue: null
};

DatePickerComponent.propTypes = {
  datePickerClass: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  selected: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  boxClass: PropTypes.string,
  tabIndex: PropTypes.number.isRequired,
  placeholderText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired
};

export default DatePickerComponent;
