import * as offerConstants from './offer.actions.constants';

const initialState = {
  activeLoans: {},
  loanLimit: {},
  allOffers: [],
  offerHistory: [],
  regenerateCheck: false
};

const offerReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case offerConstants.SET_ACTIVE_LOAN:
      return { ...state, activeLoans: data };
    case offerConstants.SET_LOAN_LIMITS:
      return { ...state, loanLimits: data };
    case offerConstants.SET_ALL_OFFERS:
      return { ...state, allOffers: data };
    case offerConstants.SET_OFFER_HISTORY:
      return { ...state, offerHistory: data };
    case offerConstants.SET_REGENERATE_CHECK:
      return { ...state, regenerateCheck: data };
    default:
      return state;
  }
};

export default offerReducer;
