import React from 'react';

export default function index({
  value,
  onChange,
  labelText,
  inputClass,
  wrapperClass,
  required,
  fullWidth,
  name,
  type = 'text',
}) {
  return (
    <div className={`input-wrapper ${wrapperClass}`}>
      {labelText && <label className='label-text'>{labelText}</label>}
      <input
        onChange={onChange}
        value={value}
        className={`input-generic ${inputClass} ${fullWidth && 'w-full'}`}
        required={required}
        name={name}
        type={type}
      />
    </div>
  );
}
