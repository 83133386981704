import React from 'react';

export default function index({
  value,
  onChange,
  labelText,
  selectClass,
  wrapperClass,
  required,
  fullWidth,
  name,
  type = 'text',
  options = [],
}) {
  return (
    <div className={`select-wrapper ${wrapperClass}`}>
      {labelText && <label className='label-text'>{labelText}</label>}
      <select
        onChange={onChange}
        value={value}
        className={`select-generic ${selectClass} ${fullWidth && 'w-full'}`}
        required={required}
        name={name}
        type={type}
      >
        {options.map((option, index) => {
          return (
            <option key={`option-${option}-${index}`} className='options'>
              {option}
            </option>
          );
        })}
      </select>
    </div>
  );
}
