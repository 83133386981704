import React, { Component } from 'react';
import { API_GET_UNLOCK_OFFER } from '../../../utils/APIUrls';
import { GET } from '../../../utils/webAPI.service';
import Button from '../../../components/Button/index';

export default class UnlockLimitModal extends Component {
  unlockLimitHandler = () => {
    let { match, history } = this.props?.propsData,
      { loanId, userId } = match.params;

    GET(API_GET_UNLOCK_OFFER(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          history.push(`/pay-app/${loanId}/${userId}/application`);
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch(err => {
        alert('Something went wrong');
        throw err;
      });
  };
  render() {
    return (
      <div className="modal_card">
        <h3 className="modal_card-heading">Regenerate Offer</h3>
        <div className="offer_wrapper">
          <div className="unlock_box">
            Are you sure you want to regenerate offer for this user?
          </div>
          <div className="limit_button">
            <Button variant="contained" onClick={this.unlockLimitHandler}>
              OK
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
