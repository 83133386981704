import React from 'react';

const applicationColumns = [
  {
    Header: 'Offer ID',
    accessor: 'offerId'
  },

  {
    Header: 'Created',
    accessor: 'createdOn'
  },
  {
    Header: 'Status',
    accessor: 'isActive',
    Cell: ({ original }) => {
      let { isActive } = original;
      return (
        <div className="offer-history">
          {isActive === true ? (
            <div className="offer-history_active">Active</div>
          ) : (
            <div className="offer-history_expired">Expired</div>
          )}
        </div>
      );
    }
  }
];

export { applicationColumns };
