import React from 'react';

export default function index({
  children,
  className,
  variant,
  onClick,
  fullWidth,
  disabled
}) {
  return (
    <button
      className={`button__wrapper 
            ${className} 
            ${variant === 'outlined' ? 'button_outlined' : 'button_contained'} 
            ${fullWidth && 'w-100'}
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
