import * as paymentConstant from './payments.actions.constants';
import {
  API_POST_PAYMENT_HISTORY,
  API_POST_FETCH_REPAYMENTS
} from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';

const getPaymentHistory = (
  userId,
  contactType,
  pageNumber,
  sortParameter,
  sortingOn
) => {
  let dataTOSend = {
    userId,
    pageNumber,
    status: null,
    contactType,
    sortingRequest: {
      sortParameter,
      sortingOn
    }
  };
  return dispatch => {
    return POST(API_POST_PAYMENT_HISTORY, dataTOSend)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data && contactType === 'supplier') {
            dispatch({
              type: paymentConstant.SET_SUPPLIER_PAYMENT_HISTORY,
              data: data.data
            });
          } else if (data.data && contactType === 'customer') {
            dispatch({
              type: paymentConstant.SET_SELF_PAYMENT_HISTORY,
              data: data.data
            });
          }
        } else if (data.code === 401) {
          alert(data?.message);
        }
      })
      .catch(err => {
        alert('Something went wrong');
        throw err;
      });
  };
};

const getRepayments = (
  userId,
  pageNumber,
  sortParameter = 0,
  sortingOn = ''
) => {
  let dataTOSend = {
    userId,
    pageNumber,
    sortingRequest: {
      sortParameter,
      sortingOn
    }
  };
  return dispatch => {
    return POST(API_POST_FETCH_REPAYMENTS, dataTOSend)
      .then(({ data }) => {
        if (data.code === 200) {
          dispatch({
            type: paymentConstant.SET_REPAYMENTS,
            data: data.data
          });
        } else if (data.code === 401) {
          alert(data?.message);
        }
      })
      .catch(err => {
        alert('Something went wrong');
        throw err;
      });
  };
};

export { getPaymentHistory, getRepayments };
