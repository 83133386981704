import * as invoicesConstants from './invoices.actions.constants';
import {
  API_POST_INVOICES,
  API_GET_LIMIT_SUMMARY,
  API_POST_FETCH_REPAYMENTS
} from '../../../utils/APIUrls';
import { GET, POST } from '../../../utils/webAPI.service';

const getInvoices = (
  userId,
  isSettled,
  pageNumber,
  sortParameter,
  sortingOn
) => {
  let dataToSend = {
    userId,
    status: null,
    pageNumber,
    isSettled,
    sortingRequest: {
      sortParameter,
      sortingOn
    }
  };
  return dispatch => {
    return POST(API_POST_INVOICES, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data && isSettled === true) {
            dispatch({
              type: invoicesConstants.SET_SETTLED_INVOICES,
              data: data.data
            });
          } else if (data.data && isSettled === false) {
            dispatch({
              type: invoicesConstants.SET_OUTSTANDING_INVOICES,
              data: data.data
            });
          }
        } else if (data.code === 401) {
          alert(data?.message);
        }
        return data;
      })
      .catch(err => {
        alert('Something went wrong');
        throw err;
      });
  };
};

const getLimitSummary = userId => {
  return dispatch => {
    return GET(API_GET_LIMIT_SUMMARY(userId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: invoicesConstants.SET_LIMIT_SUMMARY,
              data: data.data
            });
          }
        } else if (data.code === 401) {
          alert(data?.message);
        }
      })
      .catch(err => {
        alert('Something Went Wrong');
        throw err;
      });
  };
};

const getRepayments = (
  userId,
  pageNumber,
  sortParameter = 0,
  sortingOn = ''
) => {
  let dataTOSend = {
    userId,
    pageNumber,
    sortingRequest: {
      sortParameter,
      sortingOn
    }
  };
  return dispatch => {
    return POST(API_POST_FETCH_REPAYMENTS, dataTOSend)
      .then(({ data }) => {
        if (data.code === 200) {
          dispatch({
            type: invoicesConstants.SET_REPAYMENTS,
            data: data.data
          });
        } else if (data.code === 401) {
          alert(data?.message);
        }
      })
      .catch(err => {
        alert('Something went wrong');
        throw err;
      });
  };
};

export { getInvoices, getLimitSummary, getRepayments };
