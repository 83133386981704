import React, { Component } from 'react';
import ReactTable from 'react-table';
import DownArrow from '../../../assets/images/downArrow.png';
import { rupeeDecimalFormatter } from '../../../utils/utility';

export default class SelfTransferModal extends Component {
  render() {
    let { stateData } = this.props,
      {
        paymentDate,
        paymentReference,
        paymentMode,
        paymentStatus,
        invoiceAmount,
        paymentDetails = {}
      } = stateData.selfTransferDetails;
    const paymentDetailsArray = paymentDetails ? [paymentDetails] : [];

    const selfTransferColumns = [
      {
        Header: 'Account Holder',
        accessor: 'accountHolder'
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber'
      },
      {
        Header: 'IFSC Code',
        accessor: 'ifscCode'
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName'
      },
      {
        Header: 'Branch',
        accessor: 'branchName'
      },
      {
        Header: 'UPI ID',
        accessor: 'upiId'
      },
      {
        Header: 'QR Ref',
        accessor: 'qrReference'
      }
    ];
    return (
      <div className="modal-wrapper">
        <h3 className="card_heading">
          Self Transfer Details
          <img src={DownArrow} alt="download" className="downArrow" />
        </h3>
        <div className="border-bottom">
          <div className="row">
            <div className="col-md-3 text-grey">
              Payment Status
              <div className="text-bold">{paymentStatus || '-'}</div>
            </div>
            <div className="col-md-3 text-grey">
              Invoice Amount with TAX
              <div className="text-bold">
                {rupeeDecimalFormatter(invoiceAmount, '₹')}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 text-grey">
              Payment Date
              <div className="text-bold">{paymentDate || '-'}</div>
            </div>
            <div className="col-md-3 text-grey">
              Payment Mode
              <div className="text-bold">{paymentMode || '-'}</div>
            </div>
            <div className="col-md-3 text-grey">
              Payment Reference
              <div className="text-bold">{paymentReference || '-'}</div>
            </div>
          </div>
        </div>
        <h3 className="card_heading mt-4">Bank Account Details</h3>
        <ReactTable
          columns={selfTransferColumns}
          data={paymentDetailsArray || []}
          defaultPageSize={1}
          showPagination={false}
        />
      </div>
    );
  }
}
