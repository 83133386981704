import { GET } from "../utils/webAPI.service";
import { loaderModal } from "./actions";

export function getList(type, url, first_option = "") {
  return (dispatch) => {
    
    return GET(url)
      .then(({ data }) => {
        if (data.code === 200) {
          let list = [],
            responseList = data.data.responseList.sort();
          if (first_option) {
            list = [first_option, ...responseList];
          } else {
            list = data.data.responseList;
          }
          dispatch({ type, list });
        }
      })
      .catch((error) => {
        dispatch(loaderModal(false));
        throw error;
      });
  };
}
