import React, { Component } from 'react';
import Modal from 'react-modal';
import Icon from 'react-icons-kit';
import { cross } from 'react-icons-kit/metrize/cross';

class CredoModal extends Component {
  render() {
    const {
      isOpen,
      closeAction,
      title,
      RenderingComponent,
      styles,
      className,
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={null}
        style={styles}
        className={`${className}`}
      >
        <span onClick={closeAction} className={'cross-icon'}>
          <Icon icon={cross} size={22} />
        </span>
        {title ? <h3>{title}</h3> : null}
        {RenderingComponent ? <RenderingComponent {...this.props} /> : null}
      </Modal>
    );
  }
}

CredoModal.defaultProps = {
  closeAction: () => {},
  RenderingComponent: null,
  styles: {},
  className: '',
};

export default CredoModal;
