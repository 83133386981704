import { GET } from '../../utils/webAPI.service';
import { API_GET_EOD_SCHEDULE, API_GET_EOD_HISTORY } from '../../utils/APIUrls';
import * as eodConstants from './eodManagement.actions.constants';

const getEodSchedule = () => {
  return dispatch => {
    return GET(API_GET_EOD_SCHEDULE)
      .then(({ data }) => {
        if (data?.code === 200) {
          if (data.data) {
            dispatch({
              type: eodConstants.SET_EOD_SCHEDULE,
              data: data.data
            });
          } else {
            alert(data.message);
          }
        }
        return;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getEodHistory = () => {
  return dispatch => {
    return GET(API_GET_EOD_HISTORY)
      .then(({ data }) => {
        if (data?.code === 200) {
          if (data.data) {
            dispatch({
              type: eodConstants.SET_EOD_HISTORY,
              data: data.data
            });
          }
        } else {
          alert(data.message);
        }
        return;
      })
      .catch(err => {
        throw err;
      });
  };
};

export { getEodSchedule, getEodHistory };
