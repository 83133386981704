const initialState = {
  genderList: [],
};

export const listData = (state = initialState, action) => {
  const { list, type } = action;
  switch (type) {
    case "SET_GENDER_LIST":
      return {
        ...state,
        genderList: list,
      };
    case "GET_MANUAL_CHARGES":
      return {
        ...state,
        getManualChargesList: list,
      };

    default:
      return state;
  }
};
