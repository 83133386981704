import React, { Component } from 'react';

class CompareModal extends Component {
  render() {
    let { propsData } = this.props,
      { compareDetails } = propsData,
      { aadharResponse, panResponse } = compareDetails;

    return (
      <div className="compare-details-wrapper">
        <h3 className="card_heading"> Compare PAN & Aadhaar</h3>
        <div className="card my-4">
          <div className="text-grey">PAN</div>
          <div className="heading-blue">{panResponse?.panNumber || '-'}</div>
          <div className="row ">
            <div className="col-md-4 ">
              <div className="heading">Name</div>
              <div className="value">{panResponse?.panName || '-'}</div>
            </div>
            <div className="col-md-4 ">
              <div className="heading">DOB</div>
              <div className="value">{panResponse?.dob || '-'}</div>
            </div>
            <div className="col-md-4 ">
              <div className="heading"> Father’s Name</div>
              <div className="value">{panResponse?.fatherName || '-'}</div>
            </div>
          </div>
        </div>
        <div className="card my-4">
          <div className="text-grey"> Aadhaar</div>
          <div className="heading-blue">
            {aadharResponse?.aadharNumber || '-'}
          </div>
          <div className="row ">
            <div className="col-md-4 ">
              <div className="heading">Name</div>
              <div className="value">{aadharResponse?.aadharName || '-'}</div>
            </div>
            <div className="col-md-4 ">
              <div className="heading">DOB</div>
              <div className="value">{aadharResponse?.dob || '-'}</div>
            </div>
            <div className="col-md-4 ">
              <div className="heading">Gender</div>
              <div className="value">{aadharResponse?.gender || '-'}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CompareModal;
