const SET_ACTIVE_LOAN = Symbol('setActiveLoan'),
  SET_LOAN_LIMITS = Symbol('setLoanLimits'),
  SET_ALL_OFFERS = Symbol('setAllOffers'),
  SET_REGENERATE_CHECK = Symbol('setRegenerateCheck'),
  SET_OFFER_HISTORY = Symbol('setOfferHistory');

export {
  SET_ACTIVE_LOAN,
  SET_LOAN_LIMITS,
  SET_ALL_OFFERS,
  SET_OFFER_HISTORY,
  SET_REGENERATE_CHECK
};
