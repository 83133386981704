import * as invoicesConstants from './invoices.actions.constants';

const initialState = {
  activeLoans: {},
  limitSummary: {},
  outstandingInvoices: {},
  settledInvoices: {},
  repayments: {}
};

const invoicesReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case invoicesConstants.SET_ACTIVE_LOAN:
      return { ...state, activeLoans: data };
    case invoicesConstants.SET_LIMIT_SUMMARY:
      return { ...state, limitSummary: data };
    case invoicesConstants.SET_OUTSTANDING_INVOICES:
      return { ...state, outstandingInvoices: data };
    case invoicesConstants.SET_SETTLED_INVOICES:
      return { ...state, settledInvoices: data };
    case invoicesConstants.SET_REPAYMENTS:
      return { ...state, repayments: data };
    default:
      return state;
  }
};

export default invoicesReducer;
