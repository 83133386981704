import React, { Component } from 'react';
import GreenBag from '../../assets/images/Bag.svg';
import RedBag from '../../assets/images/RedBag.svg';
import CrossIcon from '../../assets/images/CrossIcon.svg';
import Tick from '../../assets/images/BlueTick.svg';
import ArrowUp from '../../assets/images/ArrowUp.svg';
import ArrowDown from '../../assets/images/ArrowDown.svg';

const Check = ({ bool }) => {
  return bool ? (
    <img src={Tick} alt="image" />
  ) : (
    <img src={CrossIcon} alt="image" />
  );
};

export default class EodCard extends Component {
  state = {
    isOpen: false
  };

  render() {
    const { data } = this.props;
    let { isOpen } = this.state;

    return (
      <div className="eod_card ">
        <div className={`eod_card-main-heading eod_status-${data.status}`}>
          {data.status ? (
            <img src={GreenBag} alt="image" />
          ) : (
            <img src={RedBag} alt="image" />
          )}

          <span>
            {' '}
            EOD History for <b>{data.ranAt ?? '-'}</b>
          </span>
        </div>
        <div className="eod_card_table">
          <div className="row eod_card_table-heading">
            <div className="col-md-2"> EoD initiated</div>
            <div className="col-md-2">EoD Completed</div>
            <div className="col-md-2">Mode</div>
            <div className="col-md-2">EoD Duration</div>
            <div className="col-md-2">System Available</div>
            <div className="col-md-2">Status</div>
          </div>
        </div>
        <div className="row eod_card_table-content">
          <div className="col-md-2">{data.startTime ?? '-'}</div>
          <div className="col-md-2">{data.endTime ?? '-'}</div>
          <div className="col-md-2">{data.mode ?? '-'}</div>
          <div className="col-md-2">{`${data.duration} sec` ?? '-'} </div>
          <div className="col-md-2">{data.systemStartTime ?? '-'}</div>
          <div className="col-md-2 ">
            <Check bool={data.status} />
            <img
              className="image"
              src={isOpen ? ArrowDown : ArrowUp}
              alt="image"
              onClick={() => {
                this.setState({ isOpen: !isOpen });
              }}
            />
          </div>
        </div>
        {/* Schedule Data Block */}
        {isOpen ? (
          <div className="row eod_inner-block">
            {Object.values(data?.statusDetails ?? {}).map((el, idx) => {
              return (
                <div
                  key={`${idx}eodchild`}
                  className="col-md-4 eod_inner-block-section "
                >
                  <div>
                    <b>{el.name.split(/(?=[A-Z])/).join('  ') ?? '-'}</b>
                  </div>
                  <div>
                    <Check bool={el.status} />
                    {el.time.split('T').join('  ') ?? '-'}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}
