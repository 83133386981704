import React, { Component } from 'react';

export default class AddCustomerModal extends Component {
  render() {
    return (
      <div className="modal-wrapper">
        <h3 className="card_heading">Add new Customer</h3>
        <div className="row">
          <div className="col-md-6">
            <div className="input_heading">
              Customer Name
              <input placeholder="Enter the  Customer Name" required />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input_heading">
              Customer Moble Number
              <input placeholder="Enter the Customer Mobile  Number" required />
            </div>
          </div>
        </div>
        <div className="save_button">
          <button className="save_button button-success">Save</button>
        </div>
      </div>
    );
  }
}
