import * as paymentConstant from './payments.actions.constants';

const initialState = {
  supplierPaymentHistory: {},
  selfPaymentHistory: {},
  repayments: {}
};

const paymentsReducer = (state = initialState, action) => {
  let { data, type } = action;
  switch (type) {
    case paymentConstant.SET_SUPPLIER_PAYMENT_HISTORY:
      return { ...state, supplierPaymentHistory: data };
    case paymentConstant.SET_SELF_PAYMENT_HISTORY:
      return { ...state, selfPaymentHistory: data };
    case paymentConstant.SET_REPAYMENTS:
      return { ...state, repayments: data };
    default:
      return state;
  }
};

export default paymentsReducer;
